import {useEffect, useRef, useState} from "react";
import './GoogleMapsSearch.css'
import {useTranslation} from "react-i18next";

const google = window.google

var openedInfowindow = null

function changeOpenedInfowindow(infowindow, openArguments) {
    if (openedInfowindow) {
        openedInfowindow.close()
    }

    infowindow.open(openArguments)
    openedInfowindow = infowindow
}

/**
 * Show a Google Map with markers.
 *
 * props.markers: [{lat, lng, label}]
 */
function GoogleMapsSearch(props) {

    const {t} = useTranslation()
    const mapRef = useRef(null)

    const [placeMarkers, setPlaceMarkers] = useState([])

    useEffect(() => {
        const map = new google.maps.Map(mapRef.current, {
            center: {lat: 45.5017, lng: -73.5673},
            gestureHandling: 'greedy',
            zoom: 10,
        })

        // Delete all previous input elements with id 'pac-input'
        const previousInputElements = document.querySelectorAll('#pac-input')
        previousInputElements.forEach(element => element.remove())

        // Create the search box and link it to the UI element. https://developers.google.com/maps/documentation/javascript/examples/places-searchbox
        const inputElement = document.createElement('input')
        inputElement.id = 'pac-input'
        inputElement.className = 'controls'
        inputElement.type = 'text'
        inputElement.placeholder = t('googleMaps.search')
        mapRef.current.parentNode.insertBefore(inputElement, mapRef.current)

        const searchBox = new google.maps.places.SearchBox(inputElement)

        map.controls[google.maps.ControlPosition.TOP_CENTER].push(inputElement)
        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds())
        })

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces()

            if (places.length === 0) {
                return
            }

            // Clear out the old markers.
            placeMarkers.forEach((marker) => {
                marker.setMap(null)
            })
            const markers = []

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds()

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry")
                    return
                }

                const icon = {
                    url: place.icon,
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(25, 25),
                }

                // Create a marker for each place.
                markers.push(
                    new google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location,
                    })
                )
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport)
                } else {
                    bounds.extend(place.geometry.location)
                }
            })
            map.fitBounds(bounds)

            setPlaceMarkers(markers)
        })

        // Markers
        if (props.markers && props.markers.length > 0) {
            props.markers.forEach(marker => {
                const entryMarker = new google.maps.Marker({
                    position: {lat: marker.lat, lng: marker.lng},
                    map: map,
                })
                // Info Window https://developers.google.com/maps/documentation/javascript/infowindows
                const infowindow = new google.maps.InfoWindow({
                    content: marker.label,
                })
                entryMarker.addListener("click", () => {
                    changeOpenedInfowindow(infowindow, {
                        anchor: entryMarker,
                        map,
                    })
                })
            })
        }
    }, [props.markers])

    return <>
        <div className="googleMap" ref={mapRef}></div>
    </>
}

export default GoogleMapsSearch
