import React, {useEffect, useState} from "react";

/**
 * An input that will wait a bit before telling its value was updated.
 * - name : The name for the input
 * - type : The input type
 * - className : The classes
 * - id : (optional) the input's id
 * - min : (optional) the input's min value
 * - max : (optional) the input's max value
 * - initialValue : The initial value
 * - waitTimeInMs : The time without changes to wait
 * - hideCircle : true to not show the save circle
 * - onChange : A callback function(newValue)
 */
function DelayedInput(props) {

    const [value, setValue] = useState(props.initialValue ? props.initialValue : '')

    console.log(`[${props.name}] Initial Value: ${props.initialValue} ; Current Value: ${value}`)

    useEffect(() => {
            setValue(props.initialValue)
        },  // eslint-disable-next-line
        [props.initialValue])

    // Limit the service calls
    useEffect(() => {
            const timoutInstance = setTimeout(() => sendUpdate(), props.waitTimeInMs)
            return () => clearTimeout(timoutInstance)
        },  // eslint-disable-next-line
        [value])

    function sendUpdate() {
        if (props.initialValue !== value) {
            props.onChange && props.onChange(value)
        }
    }

    if (props.type === 'checkbox') {
        return (<>
                {!props.hideCircle && (
                    props.initialValue === value &&
                    <i className="bi bi-check-circle bi-check-circle-checkbox text-success"></i> ||
                    <i className="bi bi-check-circle bi-check-circle-checkbox text-info"></i>
                )
                }
                <input type={props.type} className={`${props.className}`} id={props.id}
                       autoComplete="off"
                       name={props.name}
                       min={props.min}
                       max={props.max}
                       checked={value}
                       onChange={e => setValue(e.target.checked)}
                />
            </>
        )
    } else {
        const isInteger = props.type === 'integer';
        const inputType = isInteger ? 'number' : props.type

        // Remove any decimal point when the input is an integer
        function onInput(e) {
            if (isInteger) {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }
            setValue(e.target.value)
        }

        return (<>
                {!props.hideCircle && (
                    props.initialValue === value && <i className="bi bi-check-circle text-success"></i> ||
                    <i className="bi bi-check-circle text-info"></i>
                )
                }
                <input type={inputType} className={`${props.className} input-text-padding-left`} id={props.id}
                       autoComplete="off"
                       name={props.name}
                       value={value}
                       onChange={onInput}
                />
            </>
        )
    }


}

export default DelayedInput
